import * as React from 'react'
import Layout from '../../layout'
import LoginPage from '../../pagesComponents/login'

const Login = () => {
	return (
		<Layout title="Login">
			<LoginPage />
		</Layout>
	)
}

export default Login
